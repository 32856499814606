import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import React, { FC, useContext } from 'react'
import { BackgroundImageContainer, Filler, MainContainer, Title } from '../components/styles'
import IndexLayout from '../layouts'
import { ProductList, PageHeader, Pagination } from '../components'
import { PagePropsWithData, Palette, SeoData } from '../models'
import { ThemeContext } from '../contexts/theme'
import { GraphcmsProduct } from '../models/graphcms/assets'

const ShelfPage: FC<PagePropsWithData> = ({ data, uri }) => {
  const { palette } = useContext(ThemeContext)
  const { graphCmsShelf: shelf, allGraphCmsProduct: productsData } = data

  if (typeof shelf === 'undefined') {
    throw new Error('Non-existing shelf')
  }

  const products: GraphcmsProduct[] = productsData?.edges.map(({ node }) => node) || []
  const pageInfo = productsData?.pageInfo
  const match = uri.match(/^(.+)\/\d+$/)
  const baseUri = match ? match[1] : uri

  const seoData: SeoData = {
    pageUri: `/shelf/${shelf.slug}`,
    title: shelf.name,
    description: shelf.description,
    openGraphImage: shelf.backgroundImage.url,
  }

  const currentPalette: Palette = shelf.palette || palette

  const styles = {
    ProductListContainer: styled.div`
      background-color: ${currentPalette.backgroundColor.css};
      border-radius: 1em;
      padding: 2em;
      margin-bottom: 2em;
    `,
  }

  return (
    <IndexLayout seoData={seoData} palette={shelf.palette}>
      <BackgroundImageContainer
        backgroundImage={shelf.backgroundImage}
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundAttachment="fixed"
      >
        <PageHeader wavePath={shelf.wavePath} backgroundColor={currentPalette.headerBackgroundColor.css}>
          <MainContainer>
            <Title level={1} color={currentPalette.headerTextColor.css}>
              {shelf.name}
            </Title>
          </MainContainer>
        </PageHeader>

        <Filler height="12em" />

        <MainContainer>
          <styles.ProductListContainer>
            {products.length > 0 && <ProductList products={products} />}
            {pageInfo && pageInfo.pageCount > 1 && (
              <Pagination
                uri={baseUri}
                currentPage={pageInfo.currentPage}
                pageCount={pageInfo.pageCount}
                options={{ disableFirstAndLast: true, maxButtonCount: 7 }}
              />
            )}
          </styles.ProductListContainer>
        </MainContainer>
      </BackgroundImageContainer>
    </IndexLayout>
  )
}

export const query = graphql`
  query ShelfPageQuery($slug: String!, $limit: Int!, $skip: Int!) {
    graphCmsShelf(slug: { eq: $slug }) {
      name
      slug
      description
      backgroundImage {
        url
      }
      palette {
        ...PaletteProps
      }
      wavePath
    }
    allGraphCmsProduct(filter: { shelf: { slug: { eq: $slug } } }, limit: $limit, skip: $skip) {
      pageInfo {
        currentPage
        pageCount
      }
      edges {
        node {
          slug
          ebpId
          price
          name
          description
          imageUrl
          shelf {
            slug
            name
            palette {
              ...PaletteProps
            }
          }
          productVariants {
            name
            priceModifier
          }
        }
      }
    }
  }
`

export default ShelfPage
